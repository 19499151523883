.table {
	border-collapse: collapse;
	width: 100%;
	font-size: 0.8em;
	th {
		padding: 8px;
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: left;
		background-color: #05445e;
		color: white;
		font-weight: 600;
		&:first-child{
			padding-left: 20px;
		}
		&:last-child{
			padding-right: 20px;
		}
	}

	td {
		padding: 5px;
		vertical-align: middle;
		&:first-child{
			padding-left: 20px;
		}
		&:last-child{
			padding-right: 20px;
		}
	}
	
	tr {
		border-bottom: 1px solid #ddd;
		&:nth-child(even) {
			background-color: #f2f2f2;
		}
		&:hover {
			background-color: #f8f8f8;
		}
		&:last-child{
			border-bottom: none;
		}
	}
}
.container{
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0,  0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background: #05445e;
    color: #fff;
    height: 100px;
    flex: 1;
    display: flex;
}

.icon{
    width: 30%;
    svg{
        width: 100%;
        height: 100%;
    }
}

.content{
    width: 23%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    h3{
        font-weight: 900;
        font-size: 1.3em;
    }
    h4{
        font-weight: 300;
        font-size: 1em;
    }
}
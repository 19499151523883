.container {
    display: flex;
    flex-wrap: wrap;
    background-color: #e9e9e9;
    gap: 0.5%;
    justify-content: space-between;
    padding-top: 13px;
}

.card_wrapper {
    box-shadow: rgba(0, 0, 0, 0.20) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    margin-bottom: 15px;
    width: 49.5%;
}

.card_info {
    display: flex;
    height: 100%;

    .left {
        width: 50%;
    }

    .right {
        width: 50%;
        background: #fbfafa;
    }
}

.add_box_container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    &>input {
        flex: 1 1;
        height: 36px;
        border-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #4d2482;
    }

    &>button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}


.room_container {
    border-radius: 5px;
    min-height: 250px;
    margin-bottom: 10px;
    background: #4d2482;
    padding: 0 5px 5px 5px;

    .room_title {
        background: #4d2482;
        color: #fff;
        text-align: center;
        border-radius: 5px 5px 0 0;

        &>input {
            background: transparent;
            color: #fff;
            border: none;
            width: 100%;
            height: 100%;
            padding: 15px;
        }
    }

    .participants_container {
        padding: 10px;
    }

    .add_participant {
        padding: 10px;
    }
}

.card_container {
    display: flex;
    background: #fff;
    justify-content: space-between;
    margin-bottom: 5px;
}

.fair_form_container {
    min-width: 57vw;
    min-height: 70vh;
}


.manage_agenda_form_container {
    margin-bottom: 20px;

    form {
        display: flex;
        gap: 5px;
        justify-content: center;
        align-items: flex-end;
    }

    button {
        height: 40px;
        margin-bottom: 4px;
        min-width: 100px;
    }
}

.post_card_container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px;
    min-height: 300px;
}

.post_card {
    position: relative;
    width: 150px;
    height: 150px;
    background: #eee;
    padding: 15px;
    display: grid;
    border-radius: 5px;
    overflow: hidden;

    img {
        max-width: 100%;
    }

    &.selected {
        border: 2px solid #4d2482;
    }
}

.post_card_overlay {
    background: rgba(77, 36, 130, 0.7);
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        color: #00ff2c8f;
        width: 2em;
        height: 2em;
    }
}

.update_room {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.wall_container {
    display: flex;
    width: 100%;
    gap: 5px;

    section {
        margin-bottom: 0 !important;

        &:first-child,
        &:last-child {
            width: 100%;
        }

        &:nth-child(2) {
            width: 60%;
        }
    }


    &>div:global(.add_box_container) {
        margin: 10px;
    }
}

.body_container {
    height: 50vh;
    overflow-y: auto;
    padding: 10px;

}

.sponsor_list {
    border: 1px solid #16455e;
    display: flex;
    background: #fff;
    justify-content: space-between;
    margin-bottom: 5px;
}

.agenda_list {
    background: #16455e;
    justify-content: space-between;
    margin-bottom: 5px;
    padding: 5px;
    color: #fff;
    font-size: 13px;
    border-radius: 4px;
}

.select_option_container {
    display: flex;
    gap: 3px;
    flex-wrap: wrap;
    padding: 5px;
    border-bottom: 1px solid #eee;
}

.select_option {
    border: 2px solid transparent;
    height: 40px;
    width: 40px;
    border-radius: 3px;
    overflow: hidden;
    position: relative;

    img {
        height: 40px;
        width: 40px;
    }

    &.selected {
        border: 2px solid #4d2482;
    }

    .option_overlay {
        background: rgba(77, 36, 130, 0.7);
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            color: #00ff2c8f;
            width: 20px;
            height: 20px;
        }
    }
}

.floor_container,
.roof_container {
    background: #fff;
    margin: 5px 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 5px;
}
$color_1: #fff;
$color_2: #818181;
$color_3: #f1f1f1;

$bg_color1: rgba(255, 255, 255, 0);
$bg_color2: #fff;
$bg_color3: #eee;
$bg_color4: #ccc;
$bg_color5: #656565;


$main_color1: #2196f3;
$main_color2: #000;
$main_color3: #727272;
$main_color4: #d32f2f;


//reset css
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	// font-size: 100%;
	// font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

a{
    text-decoration: none;
}

body{
    margin: 0;
    padding: 0;
    line-height: 1;
    font-family: 'Roboto', sans-serif !important;
    letter-spacing: 1px;
    background-color: #e9e9e9 !important;
    color: #3e3e3e !important;
}

*, ::after, ::before {
    box-sizing: border-box;
}

*:focus {
    outline: none;
}


.main_container{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
}

.form_card{
    width: 100%;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    padding: 30px;
    background: #fbfbfb;
    min-height: 400px;
}

.page_container{
    width: calc(100% - 20px);
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    padding: 30px;
    background: #fbfbfb;
    min-height: 400px;
    margin: 10px;
}

.page_header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    &> h1{
        font-weight: 900;
    }
}

.form_submit_button{
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
     button{
        min-width: 150px;
     }
}

.swal2-container.swal2-backdrop-show{
    z-index: 9999;
}

.menu_item{
   &.active{
        color: #fff;
        background-color: #05445e !important;
        & svg{
            fill: #fff;
        }
    }

}


//global style
.gap_0{gap:0!important}
.gap_1{gap:.25rem!important}
.gap_2{gap:.5rem!important}
.gap_3{gap:1rem!important}
.gap_4{gap:1.5rem!important}
.gap_5{gap:3rem!important}
.m-0{margin:0!important}
.m_1{margin:.25rem!important}
.m_2{margin:.5rem!important}
.m_3{margin:1rem!important}
.m_4{margin:1.5rem!important}
.m_5{margin:3rem!important}
.m_auto{margin:auto!important}
.mt_0{margin-top:0!important}
.mt_1{margin-top:.25rem!important}
.mt_2{margin-top:.5rem!important}
.mt_3{margin-top:1rem!important}
.mt_4{margin-top:1.5rem!important}
.mt_5{margin-top:3rem!important}
.mt_auto{margin-top:auto!important}
.mb_0{margin-bottom:0!important}
.mb_1{margin-bottom:.25rem!important}
.mb_2{margin-bottom:.5rem!important}
.mb_3{margin-bottom:1rem!important}
.mb_4{margin-bottom:1.5rem!important}
.mb_5{margin-bottom:3rem!important}
.mb_auto{margin-bottom:auto!important}
.ml_0{margin-left:0!important}
.ml_1{margin-left:.25rem!important}
.ml_2{margin-left:.5rem!important}
.ml_3{margin-left:1rem!important}
.ml_4{margin-left:1.5rem!important}
.ml_5{margin-left:3rem!important}
.ml_auto{margin-left:auto!important}
.mr_0{margin-right:0!important}
.mr_1{margin-right:.25rem!important}
.mr_2{margin-right:.5rem!important}
.mr_3{margin-right:1rem!important}
.mr_4{margin-right:1.5rem!important}
.mr_5{margin-right:3rem!important}
.mr_auto{margin-right:auto!important}
.p_0{padding:0!important}
.p_1{padding:.25rem!important}
.p_2{padding:.5rem!important}
.p_3{padding:1rem!important}
.p_4{padding:1.5rem!important}
.p_5{padding:3rem!important}
.pt_0{padding-top:0!important}
.pt_1{padding-top:.25rem!important}
.pt_2{padding-top:.5rem!important}
.pt_3{padding-top:1rem!important}
.pt_4{padding-top:1.5rem!important}
.pt_5{padding-top:3rem!important}
.pb_0{padding-bottom:0!important}
.pb_1{padding-bottom:.25rem!important}
.pb_2{padding-bottom:.5rem!important}
.pb_3{padding-bottom:1rem!important}
.pb_4{padding-bottom:1.5rem!important}
.pb_5{padding-bottom:3rem!important}
.pl_0{padding-left:0!important}
.pl_1{padding-left:.25rem!important}
.pl_2{padding-left:.5rem!important}
.pl_3{padding-left:1rem!important}
.pl_4{padding-left:1.5rem!important}
.pl_5{padding-left:3rem!important}
.pr_0{padding-right:0!important}
.pr_1{padding-right:.25rem!important}
.pr_2{padding-right:.5rem!important}
.pr_3{padding-right:1rem!important}
.pr_4{padding-right:1.5rem!important}
.pr_5{padding-right:3rem!important}
.justify_content_between {
  justify-content: space-between !important;
}
.float_top_left, .float_top_right, .float_bottom_left, .float_bottom_right{
  position: absolute;
  z-index: 999;
}
.float_top_left{
  top: 10px;
  left: 10px;
}
.float_top_right{
  top: 10px;
  right: 10px;
}
.float_bottom_left{
  bottom: 10px;
  left: 10px;
}
.float_bottom_right{
  bottom: 10px;
  right: 10px;
}
.app_loader{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form_table_row{
  & > .form_row{
    display: flex;
    & > .form_label{
          width: 30%;
        }
    & > .form_value{
          width: 70%;
        }
  }
}
.form_row{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.form_column{
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  & > .form_label{
    margin-bottom: 0 !important;
  }
}
.form_label {
  margin-bottom: 5px;
  margin-right: 5px;
  color: $main_color3;
}
.form_value {
  margin-bottom: 5px;
  font-weight: 300;
  color: $main_color2;
  white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap;          /* Chrome & Safari */ 
  white-space: -pre-wrap;                 /* Opera 4-6 */
  white-space: -o-pre-wrap;               /* Opera 7 */
  white-space: pre-wrap;                  /* CSS3 */
  word-wrap: break-word;                  /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}
.wrap_all{
  white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
  white-space: -webkit-pre-wrap;          /* Chrome & Safari */ 
  white-space: -pre-wrap;                 /* Opera 4-6 */
  white-space: -o-pre-wrap;               /* Opera 7 */
  white-space: pre-wrap;                  /* CSS3 */
  word-wrap: break-word;                  /* Internet Explorer 5.5+ */
  word-break: break-all;
  white-space: normal;
}
.clickable{
  cursor: pointer;
}

.flex_row{
  display: flex;
  & > .action{
    min-width: 50px;
  }
}
.loader_container{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.group_container{
  display: flex;
  flex-wrap: wrap;
  & > span{
    margin: 5px;
    margin-left: 0;
    margin-bottom: 0;
    &:last-child{
      margin-right: 0;
    }
  }
}
.pull_right_container{
  display: flex;
  justify-content: flex-end;
}
.pull_left_container{
  display: flex;
  justify-content: flex-start;
}
.v_top{
  display: flex;
  align-items: flex-start;
}
.v_center{
  display: flex;
  align-items: center;
}
.v_bottom{
  display: flex;
  align-items: flex-end;
}
.h_left{
  display: flex;
  justify-content: flex-start;
}
.h_center{
  display: flex;
  justify-content: center;
}
.h_right{
  display: flex;
  justify-content: flex-end;
}
.all_center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.text_center{
  text-align: center;
}
.min_btn_width{
  min-width: 150px !important;
}
hr{
  border-top: 0.5px solid #eee;
}





 
//////////// <span>Underline</span>

// span {
//     position: relative;
//   }
  
//   span::before {
//     content: '';
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     height: 2px;
//     background-color: #3cefff;
//     transform-origin: bottom right;
//     transform: scaleX(0);
//     transition: transform 0.5s ease;
//   }
  
//   span:hover::before {
//     transform-origin: bottom left;
//     transform: scaleX(1);
//   }



// <svg viewBox="25 25 50 50">
//   <circle cx="50" cy="50" r="20"></circle>
// </svg>

// svg {
//     width: 3.75em;
//     transform-origin: center;
//     animation: rotate 2s linear infinite;
//   }
  
//   circle {
//     fill: none;
//     stroke: #fc2f70;
//     stroke-width: 2;
//     stroke-dasharray: 1, 200;
//     stroke-dashoffset: 0;
//     stroke-linecap: round;
//     animation: dash 1.5s ease-in-out infinite;
//   }
  
//   @keyframes rotate {
//     100% {
//       transform: rotate(360deg);
//     }
//   }
  
//   @keyframes dash {
//     0% {
//       stroke-dasharray: 1, 200;
//       stroke-dashoffset: 0;
//     }
//     50% {
//       stroke-dasharray: 90, 200;
//       stroke-dashoffset: -35px;
//     }
//     100% {
//       stroke-dashoffset: -125px;
//     }
//   }
  

.as_async_select{
  width: 100%;
}
.hf_select__menu{
  z-index: 999 !important;
}
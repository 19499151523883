.as__option--is-selected{
    background-color: red
}

.dropdown_container{
    position: relative;
}

.icon{
    position: absolute;
    z-index: 99;
    top: 50%;
    transform: translateY(-43%);
    left: 5px;
    & > svg{
        fill: #494949;
        width: 20px;
        height: 20px;
    }
}

.hf_select__menu{
    background: #fff;
    z-index: 99999999;
}
.container{
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0,  0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    padding: 15px;
    background: #fbfbfb;
    min-height: 150px;
    flex: 1;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    h3{
        font-size: 1.75rem;
        text-align: center;
        font-weight: 600;
        &::after{
            content: "";
            height: 2px;
            width: 70%;
            margin: auto;
            background-color: rgba(255, 255, 255, 0.12);
            display: block;
            margin-top: 10px;
        }
    }
    p{
        font-size: 1.25rem;
        font-weight: 300;
        text-align: center;
    }
}

.icon{
    position: absolute;
    height: 90px;
    width: 90px;
    font-size: 22px;
    padding: 15px;
    top: -25px;
    left: -25px;
    background-color: rgba(255, 255, 255, 0.15);
    line-height: 60px;
    text-align: right;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
        width: 30px;
        height: 30px;
        margin-top: 13px;
        margin-right: -5px;
    }
}
.container {
  height: calc(100vh - 70px);
  display: flex;
  gap: 5px;
}

.chatMenu {
  background: #fff;
  flex: 3.5;
}

.chatMenuInput {
  width: 90%;
  padding: 10px 0;
  border: none;
  border-bottom: 1px solid gray;
}

.chatBox {
  flex: 5.5;
  background: #fff;
}

.chatBoxWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.chatBoxTop {
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
}

.chatBoxBottom {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chatMessageInput {
  width: 80%;
  height: 90px;
  padding: 10px;
}

.chatSubmitButton {
  width: 70px;
  height: 40px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: teal;
  color: white;
}

.chatOnline {
  flex: 3;
  background: #fff;
}

.user_list {
  background: rgb(245, 243, 243);
  margin-bottom: 2px;

  &:hover {
    background: #eee;
  }

  &.active_list {
    background: #16455e;
    color: #fff;

    .last_message {
      color: #fff;
    }
  }
}

.chatMenuWrapper,
.chatBoxWrapper,
.chatOnlineWrapper {
  padding: 10px;
  height: 100%;
}

.noConversationText {
  color: #e0dcdc;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: default;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .chatMenu {
    flex: 1;
  }

  .chatMenuInput {
    display: none;
  }

  .chatBox {
    flex: 10;
  }

  .chatOnline {
    flex: 1px;
  }
}


.message {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .message_top {
    display: flex;

    .message_text {
      background-color: #f6f6f6;
      padding: 15px;
      border-radius: 12px;
      max-width: 300px;
    }
  }

  .message_bottom {
    font-size: 12px;
    margin-top: 10px;
  }

  &.own {
    align-items: flex-end;

    .message_text {
      background-color: #e3effd !important;
    }
  }
}


.chat_container {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  gap: 10px;
  width: 100%;

  .chat_avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #bdbdbd;
    color: #fff;

    img {
      max-width: 100%;
    }
  }

  .chat_name {
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .last_message {
    font-size: 10px;
    color: #4d2482;
  }

  .role {
    font-size: 11px;
    margin-left: 5px;
  }
}

.member_chip {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  max-width: 80%;
  margin: 5px 0;
}
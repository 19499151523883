
.profile_pic_container{
    width: 100%;
    form{
        padding: 10px;
    }
}

.profile_pic{
    width: 300px;
    height: 300px;
    img{
        max-width: 100%;
    }
}


@media only screen and (min-width: 600px) {

    .left{
        width: 330px;
    }
    .right{
        width: calc( 100% - 350px);
    }
}

.user_counter{
    
}

.welcome_card_container{

    h1{
        font-size: 1.2em;
        font-weight: 900;
        margin-bottom: 10px;
    }
    p{
        font-size: 0.9em;
        margin-bottom: 10px;
    }

    .left{
        width: 100%;
    }
    .right{
        width: 100%;
        display: flex;
        align-items: center;
        button{
            font-size: 0.7em;
        }
    }
}

@media only screen and (min-width: 600px) {

    .user_counter{
        display: flex;
    }

    .welcome_card_container{
        display: flex;
        h1{
            font-size: 2em;
        }
        p{
            font-size: 1em;
            margin-bottom: 10px;
        }
        .left{
            width: 80%;
        }
        .right{
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
    
}
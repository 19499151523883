.container{
 display: flex;
 height: 100vh; 
}

.left{
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.right{
    width: 30%;
    background: #fff;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    p{
        margin: 20px 0 ;
    }

    button{
        margin-top: 10px;
    }
}

.title{
    text-align: center;
    font-size: 1.3em;
    font-weight: 600;
    margin-bottom: 50px;
}

.sub_title{
    text-align: center;
    font-size: 1em;
    margin-bottom: 20px;
}


@media screen and (max-width: 768px) {
    .left{
        display: none;
    }
    .right{
        width: 100%;
    }
}

.user_counter{
    
}


@media only screen and (min-width: 600px) {

    .user_counter{
        display: flex;
    }

}
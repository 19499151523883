.conversation {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    gap: 10px;
  }
  
  .img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #000;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      max-width: 100%;
    }
  }
  
  .conversationName {
    font-weight: 500;
  }
  
  @media screen and (max-width: 768px) {
    .conversationName {
      display: none;
    }
  }

.section_container{
    // padding: 15px;
    border-radius: 5px;
    background: #fff;
    // overflow: hidden;
    &:not(:last-of-type){
        margin-bottom: 10px;
    }
}

.title{
    font-size: 1rem;
    padding: 10px;
    font-weight: 600;
    color: #000;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
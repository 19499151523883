.modal{
    border: 1px solid #f6f6f7;
    border-radius: 5px;
    overflow: auto;
    background: #f6f6f7;
}

.header{
    background: #000;
    height: 45px;
    background-color: Red;
    color: #000;
    position: relative;
    padding: 10px;
    border-bottom: 1px solid #f6f6f7;
    background-color: #fff;
    overflow: hidden;

    h2{
        padding: 0;
        margin: 0;
        font-size: 1.2em;
    }

    .close_button{
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.6;
        cursor: pointer;
        font-size: 1.3em;
        transition: all 0.3s;
        svg{
            fill: #000;
        }
        &:hover{
            opacity: 1;
        }
    }
}   

.body{
    padding: 15px;
    min-height: 250px;
}

.footer{
    height: 44px;
    color: #000;
    padding: 10px;
    border-top: 1px solid #f6f6f7;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 10px;

    & > button{
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
    }
}
.room_container{
    border: 1px solid #4d2482;
    border-radius: 5px;

    .room_header{
        background: #4d2482;
        color: #fff;
        padding: 8px;
    }
    .room_body{
        padding: 10px;
    }
}

.invitation_container{
    display: flex;
    gap: 5px;
    align-items: center;
    width: 100%;
}

.add_box_container{
    background: #eee;
    margin: 20px 0;
    padding: 15px;
    border-radius: 10px;

    .form_label{
        color: #000;
    }
    .form_value{
        display: flex;
        gap: 10px;
        align-items: center;  
        button{
            width: 100%;
        }
    }
   
}
.container{
    
    h1{
        font-size: 1.75rem;
        text-align: center;
        font-weight: 600;
        &::after{
            content: "";
            height: 2px;
            width: 70%;
            margin: auto;
            background-color: rgba(255, 255, 255, 0.12);
            display: block;
            margin-top: 10px;
        }
    }
    p{
        font-size: 1.25rem;
        font-weight: 300;
        text-align: center;
    }
}

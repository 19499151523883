.post_tab_container,
.art_tab_container {
    min-width: 57vw;
    min-height: 70vh;
}


.post_container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px 0;
    p {
        font-size: 0.9rem;
        span {
            color: #757272;
        }
    }
}

.post_card {
    width: 270px;
    border-radius: 5px;
    overflow: clip;
    background: #eee;
    color: #000;
    position: relative;
    min-height: 330px;
    border: 10px solid #eee;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

    img {
        width: 100%;
        height: 150px;
        border-radius: 4px;
    }

    p {
        padding: 0 5px 5px;
    }
}

.post_delete {
    position: absolute;
    bottom: 0;
    right: 0;

    &>button {
        color: #4d2482;
        font-size: 1.3rem;
        transition: all 0.1s;
    }
}

.add_image_container {
    width: 275px;
    min-height: 250px;
    border-radius: 5px;
    overflow: clip;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    border: 1px solid #ddddde;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.post_tab_container, .art_tab_container{
    min-width: 57vw;
    min-height: 70vh;
}


.post_container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
    p{
        font-size: 0.9rem;
        span{
            color: #757272;
        }
    }
}

.post_card{
    min-width: 250px;
    max-width: 250px;
    border-radius: 5px;
    overflow: clip;
    background: #fff;
    color: #000;
    position: relative;
    min-height: 330px;
    border: 10px solid #fff;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0,  0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    img{
        max-width: 100%;
    }
    p{
        padding: 0 5px 5px;
    }
}

.post_delete{
    position: absolute;
    bottom: 0;
    right: 0;
    & > button{
        color: red;
        font-size: 2rem;
        transition: all 0.1s;
        &:hover{
            font-size: 2.2rem;
        }
    }
}

.add_image_container{
    width: 250px;
    min-height: 250px;
    border-radius: 5px;
    overflow: clip;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    border: 1px solid #ddddde;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0,  0.20), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}